.Network_disconnectText__Rq5CT {
  text-align: center;
  color: #fde53a;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
}

.insideMenu {
  width: 100%;
}

@media (min-width: 1200px) {
  .insideMenu {
    width: 100%;
  }
}

/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/0148c4ca3456ea72.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/4184cf5f191b83db.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/28cea0425961ab58.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/b4c759cba7a5f011.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/de555e6bc944c11a.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/791334aa8b2b679f.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(/_next/static/media/a2411df190893074.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(/_next/static/media/60d74363e715829c.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(/_next/static/media/eb37c9fe4776ea97.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(/_next/static/media/bc381c5935034aa5.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(/_next/static/media/f565fad065ab4cf3.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(/_next/static/media/f9946f06d70a1b5b.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url(/_next/static/media/d1f5f89450ecd78c.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url(/_next/static/media/ebcf93a611f94ec0.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url(/_next/static/media/ec50b434b597bc61.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url(/_next/static/media/6be22a85bef54f08.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url(/_next/static/media/9491f41002d34397.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url(/_next/static/media/afba6ba6bf9157e8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(/_next/static/media/be843c5b0882ad66.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(/_next/static/media/67aea609c6135b3b.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(/_next/static/media/09b62d668edb05d1.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(/_next/static/media/10446295ae7f2657.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(/_next/static/media/b6ecfd81ed9f83c9.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_ee10f5';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(/_next/static/media/08404bcfb1dae67a.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Sans_Fallback_ee10f5';src: local("Arial");ascent-override: 101.35%;descent-override: 27.19%;line-gap-override: 0.00%;size-adjust: 101.13%
}.__className_ee10f5 {font-family: '__IBM_Plex_Sans_ee10f5', '__IBM_Plex_Sans_Fallback_ee10f5';font-style: normal
}.__variable_ee10f5 {--font-ibm: '__IBM_Plex_Sans_ee10f5', '__IBM_Plex_Sans_Fallback_ee10f5'
}

